import React from 'react'
import FeedbackForm from '../components/Forms/FeedbackForm'

function FeedBack() {
  return (
    <div>
        <FeedbackForm/>
    </div>
  )
}

export default FeedBack