export const internalRoutes = {
  home: "/",
  SinglePackage: "/package",
  searchresultPage: "/search",
  wishlist:"/wishlist",
  checkout:"/checkout",
  interest:"/select-your-interest",
  profile:"/user/profile",

  userSignup: "/user/signup",
  userLogin: "/user/login",
  userDashboard: "/user/dashboard",
  userForgotPassword: "/user/forgot-password",
  adminSignup: "/admin/signup",
  adminLogin: "/admin/login",
  adminDashboard: "/admin/dashboard",

  vendorSignup: "/vendor/signup",
  vendorLogin: "/vendor/login",
  vendorForgotPassword: "/vendor/forgot-password",

  vendorDashboard: "/vendor/dashboard",
  vendorProfile: "/vendor/profile",
  vendorServices: "/vendor/services",
  vendorOrders: "/vendor/orders",
  vendorOrderDeatil: "/vendor/orders/vendorOrderDeatil",
  vendorSupport: "/vendor/support",
  vendorCommunity: "/vendor/community",
  vendorCreateservice: "/vendor/Createservice",
  vendorEditservice: "/vendor/Editservice",

  aboutUs: "/about-us",
  careers: "/careers",
  pressReleases: "/press-releases",
  advertiseWithUs: "/advertise-with-us",
  grievanceOfficer: "/grievance-officer",
  popularSearches: "/popular-searches",
  evagaCommunity: "/evaga-community",
  becomeAVendor: "/become-a-vendor",
  vendorOnboarding: "/vendor-onboarding",
  cancellationPolicy: "/cancellation-policy",
  TermsAndConditions: "/terms-and-condition",
  feedbackForm: "/feedback-form",
};
